export * from "./reviewIconTypes";

export enum CUSTOM_ATTRIBUTES_FORM_TYPE {
    RADIOBUTTON = "radiobutton",
    CHECKBOX = "checkbox",
    TEXTFIELD = "textfield",
    SELECTBOX = "selectbox",
    RATING = "rating",
    FIT = "fit",
}
export enum ATTRIBUTE_TYPE {
    SINGLE_VALUE = "singleValue",
    MULTI_VALUE = "multiValue",
    RANGE = "range",
    CENTERED_RANGE = "centeredRange",
    TEXTFIELD = "textField",
    DATE = "date",
    LOCATION = "location",
}

export const PRODUCT_REVIEW_FORM_ID = "gw-rv-product-review-form";
export const REVIEW_DRAWER_FORM_ID = "review_drawer_form_id";
export const SITE_REVIEW_FORM_ID = "gw-rv-site-review-form";
export const QUESTION_FORM_ID = "gw-qa-form";

export enum REVIEW_TYPE {
    SITE_REVIEW = "site_review",
    PRODUCT_REVIEW = "product_review",
}

export enum REVIEWS_WIDGET_LAYOUT {
    LISTING_LAYOUT = "LL",
    GRID_LAYOUT = "GL",
}

export const REVIEW_FORM_MODAL = "openReviewForm";

export const SCROLL_REVIEWS_TAB = "scrollReviewsTab";
export const SCROLL_QUESTIONS_TAB = "scrollQuestionsTab";

export enum REVIEWER_NAME_DISPLAY_FORMAT {
    FULL_NAME = "fullName",
    SHORTEN_LAST_NAME = "shortenLastName",
    SHORTEN_FULL_NAME = "shortenFullName",
    ANONYMOUS = "anonymous",
    CUSTOMER_CHOICE = "customerChoice",
}

export enum REVIEWS_TYPE {
    PRODUCT = "product",
    SITE = "site",
}

export enum REVIEWS_BRANDING_PRODUCT_AVG_RATING_VISIBILITY {
    SHOW = "show",
    HIDE = "hide",
}

export enum REVIEWS_BRANDING_PRODUCT_AVG_RATING_DISPLAY_STYLE {
    SHOW_TEXT_AND_STARS = "showTextAndStars",
    SHOW_STARS_ONLY = "showStarsOnly",
    SHOW_TEXT_STARS_AND_QUESTION = "showTextStarsAndQuestion",
}

export enum REVIEWS_REPLY_STATUS {
    PUBLIC = "public",
    PRIVATE = "private",
}
